<template>
  <el-main>
    <el-form>
      <el-form-item label="选择年份：">
        <el-select v-model="year" placeholder="选择年份" @change="getList">
          <el-option v-for="item in yearList" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#f8f8fa' }" style="width: 100%">
      <el-table-column prop="month" label="月份"></el-table-column>
      <el-table-column prop="value" label="全店目标营业额（元）" width="200" align="center">
        <template #header>
          <div class="edit">
            <span>全店目标营业额（元）</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template v-slot="{ row }">
          <el-input v-model="row.value"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <Preservation @preservation="submit"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
export default {
  components: {
    Preservation,
  },
  data() {
    return {
      year: '2021',
      yearList: ['2021', '2022', '2023', '2024', '2025', '2026'],
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios.post(this.$api.set.targetTurnover, { year: this.year }).then((res) => {
        if (res.code == 0) {
          let arr = [];
          let list = res.result;
          for (let i = 1; i < 13; i++) {
            let obj = {
              key: i < 10 ? '0' + i : String(i),
              month: i + '月',
            };
            arr.push(obj);
          }
          for (let i in list) {
            for (let y in arr) {
              if (arr[y].key == i) {
                arr[y].value = list[i];
              }
            }
          }
          if (!list) {
            arr.map((item) => (item.value = 0));
          }
          this.list = arr;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    submit() {
      let list = this.list;
      let month_data = {};
      list.map((item) => (month_data[item.key] = item.value));
      this.$axios
        .post(this.$api.set.editTargetTurnover, {
          year: this.year,
          month_data,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success('保存成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  padding-bottom: 75px;
  .el-select {
    width: 150px;
  }
  .el-table {
    border: 0;
    /deep/ .el-input__inner {
      border-color: #fff;
    }
    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }
    .el-input {
      width: 120px;
    }
  }
}
</style>
